import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Article/articleLayout.js";
import { graphql } from "gatsby";
import Carousel from "../../../../components/Carousel";
import ArticleCarouselItem from "../../../../components/Article/articleCarouselItem";
export const pageQuery = graphql`
 {
  aboutImages: allFile(filter: {relativeDirectory: {eq:"articles/suane/about"}}, sort: {fields: name, order: DESC}){
      nodes{
        name
        childImageSharp{
          fluid(quality: 80){
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  landingImages: allFile(filter: {relativeDirectory: {eq:"articles/suane/landing"}}, sort: {fields: name, order: DESC}){
      nodes{
        name
        childImageSharp{
        fluid(quality: 80){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  servicesImages: allFile(filter: {relativeDirectory: {eq:"articles/suane/services"}}, sort: {fields: name, order: DESC}){
    nodes{
      name
      childImageSharp{
      fluid(quality: 80){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
 }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Suane Digitals is a creative agency that works with new businesses to increase sales through brand positioning and design. Their website was in need of a copy overhaul as it didn’t properly represent them and what they did.`}</h2>
    <p>{`Their brand voice is bold and confident, they believe in helping young brands build a strong identity so I ensured this tone and voice was reflected in the website copy.`}</p>
    <h1>{`Homepage`}</h1>
    <p>{`The former copy on the homepage wasn’t clear so I rewrote it to be more direct yet interesting enough for whoever was seeing it.`}</p>
    <Carousel mdxType="Carousel">
  {props.data.landingImages.nodes.map(node => <ArticleCarouselItem name={node.name} fluid={node.childImageSharp.fluid} mdxType="ArticleCarouselItem" />)}
    </Carousel>
    <h1>{`Services`}</h1>
    <p>{`The “Services” section also didn't have accurate descriptions so I edited each service description.`}</p>
    <Carousel mdxType="Carousel">
  {props.data.servicesImages.nodes.map(node => <ArticleCarouselItem name={node.name} fluid={node.childImageSharp.fluid} mdxType="ArticleCarouselItem" />)}
    </Carousel>
    <h1>{`About`}</h1>
    <p>{`The “About” section was rewritten to clearly show what Suane Digitals represented.`}</p>
    <Carousel mdxType="Carousel">
  {props.data.aboutImages.nodes.map(node => <ArticleCarouselItem name={node.name} fluid={node.childImageSharp.fluid} mdxType="ArticleCarouselItem" />)}
    </Carousel>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      